<template>
  <div
    :style="{
      backgroundColor: settings.reservationBackgroundColor,
      color: settings.reservationTextColor,
    }"
    style="font-size: 105%"
    class="mb-3 reservation-box"
  >
    <p v-if="!rentTotalMonths">Toistaiseksi voimassa oleva sopimus</p>
    <p v-else>Määräaikainen sopimus</p>

    <div style="display: flex">
      <p class="pr-1">
        Alkaa:
        <strong>
          {{ formatDate(searchTerms.startDate) }}
        </strong>
      </p>
      <p v-if="rentEndDate">
        Päättyy:
        <strong>
          {{ formatDate(rentEndDate) }}
        </strong>
      </p>
    </div>

    <p v-if="rentTotalMonths < 1 && rentTotalDays">
      Yhteensä: <strong> {{ rentTotalDays }} päivää </strong>
    </p>

    <p v-if="rentTotalMonths >= 1">
      Yhteensä: <strong> {{ rentTotalMonths }} kk </strong>
    </p>

    <div v-if="apartment">
      <p
        v-if="
          // +1 month or concurrent
          searchTerms.rentTime.months > 1 ||
          (searchTerms.rentTime.months == 0 && searchTerms.rentTime.days == 0)
        "
      >
        Hinta kuukaudessa:
        <strong>{{ formatCurrency(this.apartment.estimatedRent) }}</strong>
      </p>
    </div>

    <p v-if="totalPrice">
      Kokonaishinta:
      <strong> {{ totalPrice }} </strong>
    </p>

    <!-- ACTIOB BTN IF EXIST IN PARENT -->

    <slot name="actionBtn"></slot>
    <slot name="editBtn"></slot>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapState } from "vuex";

export default {
  mixins: [mixins],

  computed: {
    ...mapState("account", ["settings", "colors"]),
    ...mapState("apartment", ["searchTerms", "apartment"]),

    totalPrice() {
      if (this.rentTotalMonths == null) {
        return null;
      } else {
        // By monthPrice
        if (this.settings.priceCalcMethod == "month") {
          return this.formatCurrency(this.apartment.estimatedRent * this.rentTotalMonths);
        } else {
          return null;
        }
      }
    },

    rentTotalMonths() {
      if (!this.rentEndDate) {
        return null;
      } else {
        const start = this.searchTerms.startDate;
        const days = this.searchTerms.rentTime.days;
        const months = this.searchTerms.rentTime.months;
        let end;

        if (days > 0 && months == 0) {
          end = moment(start).add(days, "d").format("YYYY-MM-DD");
        }

        if (days == 0 && months > 0) {
          end = moment(start).add(months, "M").format("YYYY-MM-DD");
        }

        const totalMonths = moment([
          new Date(end).getFullYear(),
          new Date(end).getMonth(),
          new Date(end).getDate(),
        ]).diff(
          moment([
            new Date(start).getFullYear(),
            new Date(start).getMonth(),
            new Date(start).getDate(),
          ]),
          "months",
          true
        );

        return totalMonths;
      }
    },

    rentEndDate() {
      let endDate = "";
      const days = this.searchTerms.rentTime.days;
      const months = this.searchTerms.rentTime.months;

      // concurrent contract
      if (days == 0 && months == 0) {
        endDate = null;
      }

      if (days > 0 && months == 0) {
        endDate = moment(this.searchTerms.startDate)
          .add(days - 1, "d")
          .format("YYYY-MM-DD");
      }

      if (days == 0 && months > 0) {
        endDate = moment(this.searchTerms.startDate)
          .add(months, "M")
          .subtract(1, "d")
          .format("YYYY-MM-DD");
      }
      return endDate;
    },

    rentTotalDays() {
      const start = this.searchTerms.startDate;
      const end = this.rentEndDate;

      if (!end) {
        return null;
      }

      const days = moment([
        new Date(end).getFullYear(),
        new Date(end).getMonth(),
        new Date(end).getDate(),
      ]).diff(
        moment([
          new Date(start).getFullYear(),
          new Date(start).getMonth(),
          new Date(start).getDate(),
        ]),
        "days",
        true
      );

      return days + 1;
    },
  },
};
</script>

<style scoped src="../../assets/css/contactForm.css"></style>
