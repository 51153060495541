<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Nimi</strong>
      </p>
      <v-text-field
        v-model="fullName"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        :rules="required"
        outlined
        dense
        hide-details
      ></v-text-field>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Email</strong>
      </p>
      <v-text-field
        v-model="email"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        :rules="emailRules"
        outlined
        dense
        hide-details
      ></v-text-field>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Puhelin</strong>
      </p>
      <v-text-field
        v-model="phone"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        outlined
        dense
        hide-details
      ></v-text-field>

      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Viesti</strong>
      </p>
      <v-textarea
        v-model="message"
        class="mb-3"
        :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
        outlined
        dense
        hide-details
      ></v-textarea>
      <div style="position: absolute; top: -9999em; left: -9999em">
        <v-text-field v-model="name" label="Name"></v-text-field>
        <v-checkbox v-model="termsAgreeCheck" label="Agree terms"></v-checkbox>
      </div>
    </v-form>

    <v-btn
      :disabled="!valid"
      :color="settings.actionBtnColor"
      class="white--text mb-3"
      @click.prevent="submit"
    >
      Lähetä
    </v-btn>

    <!-- Message texts -->
    <div
      v-if="sendStatus.message"
      class="message-box"
      :style="{
        border: sendStatus.error
          ? `1px solid ${settings.errorColor}`
          : `1px solid ${settings.successColor}`,
      }"
    >
      <p :style="{ color: sendStatus.error ? settings.errorColor : settings.successColor }">
        {{ sendStatus.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data: () => ({
    valid: true,
    nameRules: [(v) => !!v || "Nimi on pakollinen"],
    emailRules: [
      (v) => !!v || "Email on pakollinen",
      (v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa",
    ],
    required: [(v) => !!v || "Pakollinen kenttä"],
  }),

  created() {
    this.$store.state.contact.sendStatus.message = "";
    this.$store.state.contact.sendStatus.error = false;
  },

  computed: {
    ...mapState("account", ["settings", "colors"]),
    ...mapState("contact", ["sendStatus"]),

    fullName: {
      get() {
        return this.$store.state.contact.contactForm.fullName;
      },
      set(val) {
        this.$store.state.contact.contactForm.fullName = val;
      },
    },
    email: {
      get() {
        return this.$store.state.contact.contactForm.email;
      },
      set(val) {
        this.$store.state.contact.contactForm.email = val;
      },
    },
    phone: {
      get() {
        return this.$store.state.contact.contactForm.phone;
      },
      set(val) {
        this.$store.state.contact.contactForm.phone = val;
      },
    },
    message: {
      get() {
        return this.$store.state.contact.contactForm.message;
      },
      set(val) {
        this.$store.state.contact.contactForm.message = val;
      },
    },

    // HIDDEN FIELD
    name: {
      get() {
        return this.$store.state.contact.contactForm.name;
      },
      set(val) {
        this.$store.state.contact.contactForm.name = val;
      },
    },

    // Check fields
    termsAgreeCheck: {
      get() {
        return this.$store.state.contact.contactForm.termsAgreeCheck;
      },
      set(val) {
        this.$store.state.contact.contactForm.termsAgreeCheck = val;
      },
    },
  },

  methods: {
    ...mapActions("contact", ["submitContactForm"]),

    validate() {
      this.$refs.form.validate();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const contactForm = {
            ...this.$store.state.contact.contactForm,
            apartment: this.$route.params.id,
          };

          if (!this.termsAgreeCheck && !this.name) {
            await this.submitContactForm(contactForm);
          }

          // Reset form
          this.$refs.form.reset();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped src="../../assets/css/contactForm.css"></style>
