<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan vuokrakohdetta..."
      class="loader"
    ></full-page-loader>

    <!-- CONTENT -->
    <v-container v-if="!loading" style="padding-top: 0">
      <!-- Header -->
      <v-row
        :style="{
          backgroundColor: settings.apartmentHeaderColor,
          color: settings.apartmentHeaderTextColor,
        }"
        class="hero-header"
        align="center"
      >
        <!-- Info -->
        <v-col cols="12">
          <h1 class="ml-2 hero-main-text">
            {{ apartment.floorPlan }} <span v-if="apartment.floorPlan">|</span>
            {{ apartment.area }} m² <span v-if="apartment.estimatedRent">| </span>
            <span v-if="apartment.estimatedRent"
              >{{ formatCardPrice(apartment.estimatedRent) }}/kk</span
            >
          </h1>

          <p class="ml-2 hero-small-text">
            <strong>{{ apartment.address }}</strong
            >, {{ apartment.zipCode }} {{ apartment.city }}, {{ apartment.houseType
            }}<span v-if="apartment.houseType && apartment.condominium.buildYear">, </span
            >{{ apartment.condominium.buildYear }}
          </p>
        </v-col>
      </v-row>

      <!-- Carousel -->
      <v-row v-if="imagesFound(apartment)" v-resize="onResize">
        <v-carousel
          hide-delimiters
          show-arrows-on-hover
          :show-arrows="allImages.length > 1"
          :height="imgContainerHeight"
        >
          <v-carousel-item v-for="(img, i) in allImages" :key="i">
            <v-img
              :src="imageUrls[i]"
              contain
              :height="imgContainerHeight"
              @error="reloadImage(img, i)"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-row>

      <!--  Description -->
      <v-row v-if="apartment.description" class="mt-4" dense>
        <v-col cols="12" sm="12" md="6" lg="7" xl="8">
          <h2 class="mb-1">Kuvaus</h2>
          <p style="white-space: pre-line">
            {{ apartment.description }}
          </p>
        </v-col>
      </v-row>

      <!-- TABLES -->
      <v-row dense class="mt-2">
        <v-col cols="12" sm="12" md="6" lg="7" xl="8">
          <!-- Apartment Info table -->
          <div v-if="selectValidFields(apartmentInfo).length > 0">
            <h2 class="mb-1">Perustiedot</h2>
            <div v-for="(li, idx) in selectValidFields(apartmentInfo)" :key="'a' + idx">
              <v-divider v-if="idx === 0" class="list-divider"></v-divider>
              <v-row dense no-gutters>
                <v-col cols="12" sm="6">
                  <p class="head-text">{{ li.head }}</p>
                </v-col>
                <v-col cols="12" sm="6">
                  <p class="val-text">{{ li.val }}</p>
                </v-col>
              </v-row>
              <v-divider class="list-divider"></v-divider>
            </div>
          </div>

          <!-- Price Info table -->
          <div class="mt-3" v-if="selectValidFields(rentInfo).length > 0">
            <h2 class="mb-1">Hinta</h2>
            <div v-for="(li, idx) in selectValidFields(rentInfo)" :key="'b' + idx">
              <v-divider v-if="idx === 0" class="list-divider"></v-divider>
              <v-row dense no-gutters>
                <v-col cols="12" sm="6">
                  <p class="head-text">{{ li.head }}</p>
                </v-col>
                <v-col cols="12" sm="6">
                  <p class="val-text">{{ li.val }}</p>
                </v-col>
              </v-row>
              <v-divider class="list-divider"></v-divider>
            </div>
          </div>

          <!-- Living price Info table -->
          <div class="mt-3" v-if="selectValidFields(priceInfo).length > 0">
            <h2 class="mb-1">Muut kustannukset</h2>
            <div v-for="(li, idx) in selectValidFields(priceInfo)" :key="'c' + idx">
              <v-divider class="list-divider" v-if="idx === 0"></v-divider>
              <v-row dense no-gutters>
                <v-col cols="12" sm="6">
                  <p class="head-text">{{ li.head }}</p>
                </v-col>
                <v-col cols="12" sm="6">
                  <p class="val-text">{{ li.val }}</p>
                </v-col>
              </v-row>
              <v-divider class="list-divider"></v-divider>
            </div>
          </div>

          <!-- Condominium Info table -->
          <div class="mt-3" v-if="selectValidFields(condominiumInfo).length > 0">
            <h2 class="mb-1">Kiinteistön ja tontin tiedot</h2>
            <div v-for="(li, idx) in selectValidFields(condominiumInfo)" :key="'d' + idx">
              <v-divider v-if="idx === 0" class="list-divider"></v-divider>
              <v-row dense no-gutters>
                <v-col cols="12" sm="6">
                  <p class="head-text">{{ li.head }}</p>
                </v-col>
                <v-col cols="12" sm="6">
                  <p class="val-text">{{ li.val }}</p>
                </v-col>
              </v-row>
              <v-divider class="list-divider"></v-divider>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="8" md="6" lg="5" xl="4">
          <!-- RESERVATION DETAILS -->
          <reservation-details v-if="settings.type == 1" class="mt-xl-3 mt-lg-3 mt-md-3">
            <!-- Action btn slot -->
            <template #actionBtn>
              <v-btn
                large
                class="white--text mt-3 mr-3"
                :color="settings.actionBtnColor"
                @click="setCartSession"
                >VUOKRAA KOHDE</v-btn
              >
            </template>

            <template #editBtn>
              <v-btn
                large
                class="white--text mt-3"
                :color="settings.errorColor"
                @click="$router.go(-1)"
                >MUUTA HAKUTERMEJÄ</v-btn
              >
            </template>
          </reservation-details>

          <!-- CONTACT FORM -->
          <div
            v-if="settings.type == 0"
            class="contact-box mt-xl-3 mt-lg-3 mt-md-3 mb-3"
            :style="{
              backgroundColor: settings.contactBackgroundColor,
              border: `1px solid ${settings.contactBorderColor}`,
            }"
          >
            <h2 class="mb-2" :style="{ color: settings.contactFieldLabelColor }">Yhteydenotto</h2>
            <contact-form></contact-form>
          </div>

          <!-- CALENDAR -->
          <Calendar v-if="settings.showReservationCalendar"></Calendar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import mixins from "../mixins/mixins";
import ContactForm from "../components/Forms/ContactForm.vue";
import Calendar from "../components/Calendar/Calendar.vue";
import ReservationDetails from "../components/Checkout/ReservationDetails.vue";
import FullPageLoader from "../components/FullPageLoader.vue";
import moment from "moment";

export default {
  mixins: [mixins],

  components: { ContactForm, Calendar, ReservationDetails, FullPageLoader },

  data() {
    return {
      loading: true,
      windowSize: null,
      imgContainerHeight: 500,
      apartmentInfo: [],
      rentInfo: [],
      priceInfo: [],
      condominiumInfo: [],
      imageUrls: [],
    };
  },

  created() {
    this.loading = true;
    this.getOneApartment(this.$route.params.id);
  },

  computed: {
    ...mapState("apartment", ["apartment", "searchTerms"]),
    ...mapState("account", ["settings"]),
    ...mapState("cart", ["cartSession"]),

    allImages() {
      if (this.apartment && this.apartment.condominium.images) {
        return [...this.apartment.condominium.images, ...this.apartment.images];
      } else return [];
    },
  },

  watch: {
    windowSize() {
      if (this.windowSize < 900) {
        this.imgContainerHeight = 300;
      } else {
        this.imgContainerHeight = 500;
      }
    },

    apartment(val) {
      this.makeList(val);
      this.loading = false;
    },

    allImages: {
      handler: "updateImageUrls",
      immediate: true,
    },
  },

  methods: {
    ...mapActions("apartment", ["getOneApartment"]),
    ...mapActions("cart", ["createCartSession", "updateCartSession"]),

    updateImageUrls() {
      this.imageUrls = this.allImages.map((img) => this.getImageCloudFrontUrl(img));
    },

    reloadImage(img, index) {
      setTimeout(() => {
        const imageUrl = this.getImageCloudFrontUrl(img);
        this.$set(this.imageUrls, index, imageUrl);
      }, 2000);
    },

    onResize() {
      this.windowSize = window.innerWidth;
    },

    async setCartSession() {
      if (!this.cartSession) {
        this.createCartSession({
          apartmentId: this.apartment._id,
          searchTerms: this.searchTerms,
        });
      } else {
        if (this.cartSession.apartment._id != this.apartment._id) {
          this.createCartSession({
            apartmentId: this.apartment._id,
            searchTerms: this.searchTerms,
          });
        } else {
          try {
            await this.updateCartSession({
              sessionId: this.cartSession._id,
              searchTerms: this.searchTerms,
            });
            this.$router.push("/checkout");
          } catch (err) {
            this.showPopup(err, "error");
          }
        }
      }
    },

    makeList(a) {
      this.apartmentInfo = [
        { head: "Vapautuu", val: this.getReleaseDate(), tag: "apartmentRelease" },
        {
          head: "Vuokrataan kalustettuna",
          val: a.furnished === true ? "Kyllä" : "Ei",
          tag: "aparmentFurnished",
        },
        { head: "Sijainti", val: `${a.address}, ${a.zipCode} ${a.city}`, tag: "apartmentLocation" },
        { head: "Kaupunki", val: a.city, tag: "apartmentCity" },
        { head: "Vuokrakohteen tyyppi", val: a.houseType || "", tag: "apartmentType" },
        {
          head: "Kerros",
          val:
            a.floor && a.condominium.floors ? `${a.floor}/${a.condominium.floors}` : a.floor || "",
          tag: "apartmentFloor",
        },
        { head: "Pinta-ala", val: `${a.area} m²`, tag: "apartmentArea" },
        { head: "Kokoonpano", val: a.floorPlan, tag: "apartmentFloorPlan" },
        { head: "Kunto", val: this.getCondition(a.currentCondition), tag: "apartmentCondition" },
        // { head: "Vapautuu", val: "heti", tag: "apartmentRelease" },
        {
          head: "Parveke",
          val: a.balcony === true ? "Kyllä" : a.balcony === false ? "Ei" : "",
          tag: "apartmentBalcony",
        },
        {
          head: "Lemmikkieläimet sallittu",
          val: a.animals === true ? "Kyllä" : a.animals === false ? "Ei" : "",
          tag: "aparmentAnimals",
        },
        // {
        //   head: "Muut ehdot",
        //   val: "kotivakuutus vaaditaan, tupakointi kielletty, mitä jos tämä jatkuu ihan perhansti",
        //   tag: "apartmentTerms",
        // },
      ];

      this.rentInfo = [
        { head: "Vuokra / kk", val: this.formatCurrency(a.estimatedRent), tag: "rent" },
        { head: "Vakuus", val: this.formatCurrency(a.deposit), tag: "deposit" },
        // { head: "Vuokra-aika", val: "toistaiseksi voimassa oleva jatkuuu....", tag: "rentTime" },
      ];

      this.priceInfo = [
        {
          head: "Vesimaksu",
          val: a.condominium.waterCost ? this.formatCurrency(a.condominium.waterCost) : "",
          tag: "water",
        },
        { head: "Sähkömaksu", val: this.formatElectricity(a), tag: "electricity" },
      ];

      this.condominiumInfo = [
        { head: "Taloyhtiön nimi", val: a.condominium.name, tag: "condominiumName" },
        { head: "Rakennusvuosi", val: a.condominium.buildYear || "", tag: "condominiumBuildYear" },
        {
          head: "Tontin pinta-ala",
          val: a.condominium.plotArea ? `${a.condominium.plotArea} m²` : "",
          tag: "condominiumPlotArea",
        },
        { head: "Kerroksia", val: a.condominium.floors || "", tag: "condominiumFloors" },
        {
          head: "Hissi",
          val:
            a.condominium.elevator === true
              ? "Kyllä"
              : a.condominium.elevator === false
              ? "Ei"
              : "",
          tag: "condominiumElevator",
        },
        {
          head: "Taloyhtiösauna",
          val: a.condominium.sauna === true ? "Kyllä" : a.condominium.sauna === false ? "Ei" : "",
          tag: "condominiumSauna",
        },
        { head: "Lämmitys", val: a.condominium.heating, tag: "condominiumHeating" },
        { head: "Antennijärjestelmä", val: a.condominium.antennaSystem, tag: "condominiumAntenna" },
        { head: "Energialuokka", val: a.condominium.energyClass, tag: "condominiumEnergyClass" },
      ];
    },

    selectValidFields(arr) {
      let allFields = [
        ...this.settings.apartmentFields,
        ...this.settings.rentFields,
        ...this.settings.priceFields,
        ...this.settings.condominiumFields,
      ];

      let array = [];

      arr.forEach((el) => {
        const index = allFields.findIndex((field) => field.tag == el.tag);

        if (index !== -1) {
          // do not show apartment release when using reservations
          if (this.settings.type == 1 && el.tag == "apartmentRelease") {
            return;
          } else {
            array.push(el);
          }
        }
      });

      return array;
    },

    getCondition(val) {
      let string;

      switch (val) {
        case 1:
          string = "Hyvä";
          break;
        case 2:
          string = "Tyydyttävä";
          break;
        case 3:
          string = "Välttävä";
          break;
        default:
          string = "";
      }

      return string;
    },

    formatElectricity(val) {
      if (val.electricityType) {
        let string;

        switch (val.electricityType) {
          case 1:
            string = "Oma sähkösopimus";
            break;
          case 2:
            string = `Kiinteä, ${this.formatCurrency(val.fixedElectricity)}/kk`;
            break;
          case 3:
            string = "Sisältyy vuokraan";
            break;
          default:
            string = "";
        }

        return string;
      } else {
        return "";
      }
    },

    getReleaseDate() {
      if (this.apartment.reservedDates.length > 0) {
        let lastEnd = Number.NEGATIVE_INFINITY;

        this.apartment.reservedDates.forEach((el) => {
          if (new Date(el.end).getTime() > lastEnd) {
            lastEnd = new Date(el.end).getTime();
          }
        });

        const showReservationsBefore = this.settings.showReservationsBefore;
        const lastCalendarDateTime = new Date(
          moment(new Date())
            .add(showReservationsBefore - 1, "days")
            .format("YYYY-MM-DD")
        ).getTime();

        if (lastCalendarDateTime == lastEnd) {
          return "Ei tiedossa";
        } else {
          return moment(lastEnd).add(1, "days").format("DD.MM.YYYY");
        }
      } else {
        return "Heti";
      }
    },
  },
};
</script>

<style scoped>
.hero-header {
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 20px;
  }

  .hero-main-text {
    font-size: 22px;
  }

  .hero-small-text {
    font-size: 13px;
  }

  .head-text,
  .val-text {
    font-size: 14px;
  }
}

.heading-wrapper {
  display: flex;
}

.head-text {
  font-weight: 600;
}

.v-divider {
  border-color: rgb(231, 231, 231) !important;
}

.contact-box {
  border-radius: 7px;
  padding: 23px 25px 25px 25px;
}

.list-divider {
  margin-bottom: 2px;
  margin-top: -2px;
}

.loader {
  height: 100vh;
}
</style>
