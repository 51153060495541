<template>
  <Calendar
    :style="{
      fontFamily: settings.font,
    }"
    locale="fi"
    :columns="$screens({ default: 1 })"
    :rows="$screens({ default: this.settings.showReservationCalendarMonths })"
    :is-expanded="$screens({ default: true, lg: true, xl: true })"
    :attributes="attrs"
    :disabled-dates="getDisabledDates"
  ></Calendar>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapState } from "vuex";
import Calendar from "v-calendar/lib/components/calendar.umd";

export default {
  mixins: [mixins],

  components: {
    Calendar,
  },

  data() {
    return {
      dates: [],
    };
  },

  computed: {
    ...mapState("account", ["settings"]),
    ...mapState("apartment", ["apartment", "searchTerms"]),

    attrs() {
      let attrs = [];
      // RESERVATIONS
      if (this.settings.type == 1) {
        attrs = [
          // RESERVED
          {
            key: "reserved",
            highlight: {
              style: {
                // button style
                background: this.settings.errorColor,
              },
              contentStyle: {
                // Text styles
                color: "white",
              },
            },
            excludeDates: [{ start: new Date(), span: this.settings.reserveBefore }],
            popover: {
              label: "Varattu",
              hideIndicator: true,
            },
            order: 1,
            dates: this.apartment.reservedDates.length > 0 ? this.apartment.reservedDates : null,
          },
          // FREE
          {
            key: "free",
            excludeDates: [
              ...this.apartment.reservedDates,
              { start: new Date(), span: this.settings.reserveBefore },
              ...this.getDisabledDates,
              ...this.getOwnReservation,
            ],
            popover: {
              label: "Vapaa",
              hideIndicator: true,
            },
            dates: {},
          },
          // NOT AVAILABLE
          {
            key: "notAvailable",
            popover: {
              label: "Ei varattavissa",
              hideIndicator: true,
            },
            excludeDates: [...this.getOwnReservation],
            dates: [
              ...this.getDisabledDates,
              { start: new Date(), span: this.settings.reserveBefore },
            ],
          },
          // OWN RESERATION
          {
            key: "ownReservation",
            highlight: {
              style: {
                // button style
                background: this.settings.actionBtnColor,
              },
              contentStyle: {
                // Text styles
                color: "white",
              },
            },
            popover: {
              label: "Sinun varauksesi",
              hideIndicator: true,
            },
            dates: this.getOwnReservation,
          },

          // TODAY
          {
            key: "today",
            highlight: {
              style: {
                background: this.settings.primaryColor,
                // button style
              },
              contentStyle: {
                // Text styles
                color: "white",
              },
            },
            popover: {
              label: "Tämä päivä",
              hideIndicator: true,
            },
            order: 2,
            dates: [new Date()],
          },
        ];
      }
      // DEFAULT
      else {
        attrs = [
          // RESERVED
          {
            key: "reserved",
            highlight: {
              style: {
                // button style
                background: this.settings.errorColor,
              },
              contentStyle: {
                // Text styles
                color: "white",
              },
            },
            // excludeDates: [{ start: new Date(), span: this.settings.reserveBefore }],
            popover: {
              label: "Varattu",
              hideIndicator: true,
            },
            order: 1,
            dates: this.apartment.reservedDates.length > 0 ? this.apartment.reservedDates : null,
          },
          // FREE
          {
            key: "free",
            excludeDates: [
              ...this.apartment.reservedDates,
              { start: new Date(), span: this.settings.reserveBefore },
              ...this.getDisabledDates,
            ],
            popover: {
              label: "Vapaa",
              hideIndicator: true,
            },
            dates: {},
          },
          // TODAY
          {
            key: "today",
            highlight: {
              style: {
                background: this.settings.primaryColor,
                // button style
              },
              contentStyle: {
                // Text styles
                color: "white",
              },
            },
            popover: {
              label: "Tämä päivä",
              hideIndicator: true,
            },
            order: 2,
            dates: [new Date()],
          },
        ];
      }

      return attrs;
    },

    getDisabledDates() {
      let dates = [];
      if (this.apartment.reservedDates) {
        const todayTime = new Date(moment(new Date()).format("YYYY-MM-DD")).getTime();

        // Show can start only after x days from today
        const showReservationsBeforeDay = new Date(
          moment(todayTime).add(this.settings.showReservationsBefore, "days")
        ).getTime();

        dates = [
          {
            start: null, // From the beginning of times
            end: new Date(todayTime), // Until today
          },
          {
            start: new Date(showReservationsBeforeDay),
          },
          { start: new Date(), span: this.settings.reserveBefore },
        ];
      }
      return dates;
    },

    getOwnReservation() {
      let dates = [];

      if (this.settings.type == 1) {
        const days = this.searchTerms.rentTime.days;
        const months = this.searchTerms.rentTime.months;
        if (months == 0 && days == 0) {
          dates.push({ start: new Date(this.searchTerms.startDate) });
        }

        if (months > 0 && days == 0) {
          const end = new Date(
            moment(this.searchTerms.startDate)
              .add(months, "M")
              .subtract(1, "d")
              .format("YYYY-MM-DD")
          );
          dates.push({ start: new Date(this.searchTerms.startDate), end });
        }
        if (days > 0 && months == 0) {
          const end = new Date(
            moment(this.searchTerms.startDate)
              .add(days, "days")
              .subtract(1, "d")
              .format("YYYY-MM-DD")
          );
          dates.push({ start: new Date(this.searchTerms.startDate), end });
        }
      }

      return dates;
    },
  },
};
</script>

<style scoped src="../../assets/css/calendar.css"></style>
